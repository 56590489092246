<template>
    <div class="page">
  
      <div class="box1">
            <div></div>
            <img src="@/assets/gis/caso.png"  alt="">
      </div>
      <div class="box2">
            <div class="box3">
                <div class="from">
                    <div class="title">
                        第三届校园气象观测和应用挑战赛
                    </div>
                    <div class="list">
                        <div 
                            @click="goSignUp(item.val)"
                            v-for="item in typeList"
                            :key="item.val"
                        >
                            <img :src="item.icon" alt="">
                        </div>
                    </div>
                </div>
            </div>
      </div>
      <el-dialog
          title="模版下载"
          :visible.sync="flag"
          width="20%"
      >
          <el-radio-group v-model="radio">
            <div style="margin-bottom: 10px;">
              <el-radio label="https://kxwj.cn/observer/files/第三届校园气象观测和应用挑战赛.pdf">第三届校园气象观测和应用挑战赛的通知</el-radio>
            </div>
            <div style="margin-bottom: 10px;">
              <el-radio label="https://kxwj.cn/observer/files/活动申报表.pdf">附件1:活动申报表模版</el-radio>
            </div>
            <div style="margin-bottom: 10px;">
              <el-radio label="https://kxwj.cn/observer/files/气象观测记录表.pdf">附件2:气象观测记录表</el-radio>
            </div>
            <div style="margin-bottom: 10px;">
              <el-radio label="https://kxwj.cn/observer/files/报告或小论文格式模板.docx">报告或小论文格式模板</el-radio>
            </div>
          </el-radio-group>
          <span slot="footer" class="dialog-footer">
            <el-button @click="flag = false">取 消</el-button>
            <el-button type="primary" @click="submit">确 定</el-button>
          </span>
      </el-dialog>
    </div>
  </template>
  <script>
  import $cookies from "vue-cookies";
  // import axios from 'axios';
  import { $activity } from "@/services";
  export default {
    name: "SignType",
    data () {
      return {
        userInfo:'',
        flag:false,
        typeList: [
          {
            val: 3,
            name: "获得报名",
            icon: require("@/assets/activity/01.jpg"),
          },
          {
            val: 1,
            name: "气象观测活动",
            icon: require("@/assets/activity/02.jpg"),
          },
          {
            val: 2,
            name: "报告小论文",
            icon: require("@/assets/activity/03.jpg"),
          },
          
          {
            val: 4,
            name: "模板下载",
            icon: require("@/assets/activity/04.jpg"),
          },
          
        ],
        radio:'https://kxwj.cn/observer/files/报告或小论文格式模板.docx',
        showReport:false
      };
    },
    created() {
      this.userInfo = $cookies.get("userInfo");
      this.getSignInfo()
    },
    methods: {
      // 获取报名信息
      async getSignInfo() { 
        let params = {}
        params.pageNum = 1
        params.pageSize = 999
        params.keyword = ''
        params.userId = this.userInfo.id
        let res = await $activity.signList(params)
        let data = res.data.records
        let index = data.findIndex(v => v.userId === this.userInfo.id)
        if (index !== -1) {
          this.showReport = true
        } else {
          this.showReport = false
        } 
      },
      goSignUp (type) {
        if (type === 3) {
          if (!this.userInfo) {
            this.$confirm('当前未登录,请登录后进行报名操作!!','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
              this.$router.push('/login?redirect='  + 'info')
            })
            return
          }
          this.$router.push(
            {
              name:'info',
              path:'/info'
            }
          )
        } else if (type === 4) {
            // window.open('https://kxwj.cn/observer/files/报告或小论文格式模板.docx', '_blank');
            this.flag = true
        } else if (type === 1) {
          if (!this.userInfo) {
            this.$confirm('当前未登录,请登录后进行报名操作!!','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
              this.$router.push('/login?redirect=' + 'uploadData')
            })
            return
          }
          if (!this.showReport) {
            this.$message.error('请先报名参赛!')
            return
          }
          this.$confirm('当前未登录,请登录后进行报名操作!!','提示',{
              confirmButtonText: '确定',
              cancelButtonText: '取消',
          }).then(() => {
            this.$router.push('/login?redirect=' + 'uploadData')
          })
          this.$router.push({
              name:'uploadData',
              path:'/uploadData'
          })
        } else if (type === 2) {
            if (!this.userInfo) {
              this.$confirm('当前未登录,请登录后进行报名操作!!','提示',{
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
              }).then(() => {
                this.$router.push('/login?redirect=' + 'signList')
              })
              return
            }
            if (!this.showReport) {
              this.$message.error('请先报名参赛!')
              return
            }
            this.$confirm('需完成至少15天的观测记录后，才能上传小论文','提示',{
                confirmButtonText: '确定',
                cancelButtonText: '取消',
            }).then(() => {
              this.$router.push({
                  name:'signList',
                  path:'/signList'
              })
            })
        }
      },
      async submit() {
          window.open(this.radio,'_blank')
          // const name = this.radio.split('files/')[1]
          // const response = await axios({
          //   url: this.radio,
          //   method: 'GET',
          //   responseType: 'blob',
          // })
          // const url = window.URL.createObjectURL(new Blob([response.data]))
          // const link = document.createElement('a');
          // link.href = url;
          // link.setAttribute('download', name);
          // document.body.appendChild(link);
          // link.click()
      }

    },
  };
  </script>
  <style lang="less" scoped>
  .page {
    width: 100vw;
    overflow: hidden;
    .box1 {
      height: 10vh;
      position: relative;
      div {
        width: 100%;
        height: 100%;
        background-color: #158bb8;
      }
      img {
        position: absolute;
        width: 30%;
        bottom: 0;
        left: calc(50% - 15%);
      }
    }
    .box2 {
      height: 90vh;
      background-color: #cdd1d3;
      position: relative;
      .box3 {
        background-image: url(../../assets/back.png);
        position: absolute;
        top: 0;
        width: 100%;
        height: 90vh;
        display: flex;
        justify-content: center;
        align-items: center;
        .from {
          padding: 30px;
          border-radius: 3px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          .title {
            font-size: 48px;
          }
          .list {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            width: 100vw;
            margin-top: 40px;
            padding: 0 200px;
            > div {
              box-shadow: 0px 2px 6px 0px rgba(181, 181, 181, 0.9);
              margin-top: 50px;
              background-color: #e2eaee;
              > img {
                width: 300px;
              }
            }
            > div:hover {
              background-color: #50ccf5;
            }
          }
  
          .title {
            p {
              margin-bottom: 15px;
              font-size: 24px;
              a {
                text-decoration: none;
              }
            }
          }
        }
      } 
    }
  }
  </style>